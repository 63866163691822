import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "開発チームと一体となった QA",
  "date": "2021-03-09T08:53:56.000Z",
  "slug": "entry/2021/03/09/175356",
  "tags": ["medley"],
  "hero": "./2021_03_09.png",
  "heroAlt": "QA"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`みなさんこんにちは、メドレーの QA エンジニア かみむら です。
入社して間もなく 1 年になります。
CLINICS 開発チームの QA 活動を行っています。`}</p>
    <p>{`私自身の経歴としては、テスト・品質関連業務に足を突っ込んでから早 20 年になろうとしています。
2020 年はメドレーの QA エンジニアが一気に 0 名 →2 名になりました。
先日 `}<a parentName="p" {...{
        "href": "/entry/2021/01/15/180126"
      }}>{`Magic Pod 導入の記事`}</a>{` を公開した米山とはかつての同僚でもあります。
現在は別々の部署に所属していますが、お互い得意分野を発揮しつつ時折情報交換や相談ごとなどをしているような関係性です。
自分とは違うタイプの同職種がいると、何かと捗ります（その辺りはまた別の機会に…）。`}</p>
    <p>{`CLINICS 開発チームでは、エンジニア・デザイナー・ QA エンジニアがワンチームで開発を進めています。
これまで私が経験してきた現場では、QA は開発チームの外側にいる（ステークホルダーとして）ことが多く、新鮮な気持ちでいます。`}</p>
    <p>{`この記事では私の入社以降取り組んできた QA 活動の概要についてお話ししたいと思います。`}</p>
    <h1>{`「CLINICS の QA」とは？`}</h1>
    <p>{`さて、何しろこれまで「QA エンジニア」という職種のひとが存在しなかった開発チームのため、まずは「CLINICS に必要な QA ってなんだろう？」というところから考えはじめました。
もちろん、数々のプロダクトを大きな障害なくリリース・運用してきているので、それなりに QA/テストの技術力はあるはずです。`}</p>
    <p>{`入社前にも、何度も`}</p>
    <ul>
      <li parentName="ul">{`なぜ（他の手段ではなく）QA エンジニアの採用が必要なのか？`}</li>
      <li parentName="ul">{`QA エンジニアにどんな役割を期待しているのか？`}</li>
    </ul>
    <p>{`といった点を開発チームの上長と話し合いました。`}</p>
    <h2>{`なぜ（他の手段ではなく）QA エンジニアの採用が必要なのか？`}</h2>
    <p>{`私は第三者検証会社に所属していた期間が長いこともあり、品質についての悩みがある開発チームにテスト支援だったりコンサルティング的役割で関わることが多かったので、「てっとり早く他社に相談するのではなく、採用したいのはなぜだろう？」という疑問が単純にありました。`}</p>
    <p>{`現場の思いとして、以下の点が挙げられていました。`}</p>
    <ul>
      <li parentName="ul">{`プロダクト開発エンジニアがリリース時のリグレッションテスト（シナリオテスト）をメンテ・実施しているが、CLINICS（電子カルテ）の複雑さに追いついていくのが難しくなってきた`}</li>
      <li parentName="ul">{`ここに対して専門性をもって取り組むことで、複雑なドメイン知識を扱うプロダクトを安定して開発リリースできる仕組みを作りたい`}</li>
    </ul>
    <h2>{`QA エンジニアにどんな役割を期待しているのか？`}</h2>
    <p>{`描いている組織体制像としては以下のようなお話でした。`}</p>
    <ul>
      <li parentName="ul">{`QA エンジニアにテストフェーズだけ縦割り的に関わってもらうのではなく、プロダクト開発チームとしてひとつになって、有るべき開発プロセスを一緒につくり上げていきたい`}</li>
      <li parentName="ul">{`開発エンジニアがテストに関してしっかりと理解をしていくことで、そもそも品質の高いプロダクトをつくることができる、といった世界を目指したい`}</li>
    </ul>
    <p>{`これら課題に対してチャレンジ的に「やってみたい」と強く思ったのと、私はこれまでにいろいろな現場の開発体制の中でテストエンジニア/QA エンジニアとして活動してきていましたが、「プロダクト開発チームとしてひとつになって」というところがすごく「それ良いな！」と思ったのを覚えています。
専門職に任せるのではなく、「一緒に理想の世界をつくりあげたい」という気持ちがとても見える良い組織だと思いました。`}</p>
    <h2>{`「QA エンジニア」「テストエンジニア」「SET/SWET」`}</h2>
    <p>{`ここで少し職種名に関する補足説明をしますと、「QA エンジニア」という呼称は比較的新しい概念なんじゃないかと思います。`}</p>
    <p>{`一般的には「テストエンジニア」と言い、文字通り「テスト業務に特化したエンジニア」を指していました。その後『`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/%E3%83%86%E3%82%B9%E3%83%88%E3%81%8B%E3%82%89%E8%A6%8B%E3%81%88%E3%81%A6%E3%81%8F%E3%82%8B-%E3%82%B0%E3%83%BC%E3%82%B0%E3%83%AB%E3%81%AE%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E9%96%8B%E7%99%BA-%E3%82%B8%E3%82%A7%E3%83%BC%E3%83%A0%E3%82%BA%E3%83%BB%E3%82%A6%E3%82%A3%E3%83%86%E3%82%AB%E3%83%BC/dp/482228512X"
      }}>{`テストから見えてくる グーグルのソフトウェア開発`}</a>{` 』（2013 年日本語版発行）から「SET/SWET（Software Engineer in Test）」が日本でも認知され、国内の導入事例が出てきたことで一気に広まった印象です。`}</p>
    <p>{`私の解釈では、「QA エンジニア」と呼称する場合、「テストエンジニア」や「SET/SWET」の素養も含みつつテスト以外にも「品質向上のための活動全般を積極的に担う役割」という意味合いが濃くなるんじゃないかと捉えています。`}</p>
    <h1>{`CLINICS のありたい「QA」の姿`}</h1>
    <p>{`上長と話し合った結果、以下のような活動をメインに据えていきましょうということになりました。`}</p>
    <ol>
      <li parentName="ol">{`（現状行っている）テストの改善`}</li>
      <li parentName="ol">{`プロセス改善`}</li>
      <li parentName="ol">{`知識の底上げ`}</li>
    </ol>
    <p>{`それぞれのトピックについて、現在 CLINICS の QA 活動としてどのように取り組んでいるかを 1 つずつ詳しく説明していきます。`}</p>
    <h2>{`「テストの改善」`}</h2>
    <p>{`現状 CLINICS の開発サイクルは週 1 回のリリースとしています。
毎回リリース用にコードフリーズした環境に対してリグレッションテストを開発チーム全員で手作業（マニュアルテスト）により行っています。
日々の機能追加や改修の際に手をいれてはいますが、リグレッションテストのシナリオもツギハギ感がみえるようになってきました。
そして増えてきたシナリオによってどこがどう品質担保されているのか見通しが悪くなっている点が大きな悩みでした。`}</p>
    <p>{`そこでまず、「現状のシナリオテストを分析し、全体的に再設計する」という計画をたて、現在は開発チームの中でもカルテに造詣の深い一部メンバーで定期的に MTG（レビュー会）を行いながらテスト設計の方針を組み立てています。
設計図ではマインドマップツールやマトリクスを作成して方向性や粒度をすり合わせしています。`}</p>
    <p>{`私自身も、今までの業務でこれだけじっくり丁寧にテスト設計をしてきたことがないため、厳しくもたいへんやりがいのあるタスクとなっています。`}</p>
    <h2>{`「プロセス改善」`}</h2>
    <p>{`こちらはテストそのものではなく、開発チームのルーチンワークや体制に関わる改善です。`}</p>
    <p>{`種まき的にスモールチームで新しいふりかえり手法を試してみたり、開発定例会で共有している障害情報とテスト実施中に見つかったバグの情報を一元化して残す仕組みを導入したりなど行いました。
特に「ふりかえり」については常に改善を意識できるプロセスで、上手なふりかえりをすればするほど開発品質が向上すると考えられているため、勉強会の後にフィードバックコメントをもらう仕組みをつくったりなどちょっとした隙間にも「ふりかえり」を小さく回せるように腐心しています。`}</p>
    <p>{`それとまだ着手できていませんが、記録した障害・バグ情報も近いうちに分析・分類していって今後の開発に役立てたいと考えています。
バグ分析は時間がかかるのでなかなかサクッとはいきませんが、長期的視点では有用な財産になります。`}</p>
    <p>{`また「開発プロセス」「業務フロー」自体の現状の悩みごとを現場の声として私が直接探るためと、開発チーム内で「共通の目標」を認識するためのブレストをリード陣と行いました。
進め方は「`}<a parentName="p" {...{
        "href": "https://www.software-quasol.com/sapid3-0/"
      }}>{`SaPID`}</a>{` 」という改善手法を参考にしています。`}</p>
    <blockquote>
      <p parentName="blockquote">{`SaPID とは、”Systems analysis/Systems approach based Process Improvement methoD”の略語で、当事者自らが（最終的には仲間と共に）解決すべき問題点を特定し、現実的に解決、改善、そして革新を実現しながら段階的・継続的に自律運営へのゴールを目指す手法です。`}</p>
      <p parentName="blockquote">{`誰かにやらせる、やらされるのではなく、当事者自らの意思、チーム・組織の意思で自律的に運営を進めることを志向するのが特徴です。`}</p>
    </blockquote>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210305/20210305203657.png",
      "alt": "20210305203657.png"
    }}></img>
    <p>{`コロナ禍の中、日によってリモートで参加のメンバーがいたりなどふせんをつかったワークにも工夫が必要でしたが、最終的には「共通の目標」として`}</p>
    <ul>
      <li parentName="ul">{`自分と身の周りに役に立つ状況をつくる`}</li>
      <li parentName="ul">{`世間に認知されるプロダクトをつくる`}</li>
    </ul>
    <p>{`というような定義をつくることができました。`}</p>
    <h2>{`「知識の底上げ」`}</h2>
    <p>{`CLINICS はこれまで中途採用メンバーが多かったため、OJT 中心で体系的な教育はまだまだ整備をしている段階です。
新卒入社も増えてきている昨今ではメンバー全体で知識レベルが合わないことによる弊害が出てきており、目線を合わせていくことが喫緊の課題でした。`}</p>
    <p>{`普段の業務の中で断片的な情報を得ることはできてもなかなか体系的な知識を効率的に身につけることは難しいので（専門書は分厚くてハードルが高い）、上長からのたっての願いでもあり、QA に従事している者にとっては割と初歩的なテスト技法から教えることにしました。
私のようにずっと QA 活動をしてきた者にとっては当たり前の技法でも、開発エンジニアにとっては意外と知る機会がなかったりするものです。
覚えておくとテストの段階だけではなく設計品質もあげることができるので、定着するように日々取り組んでいます。`}</p>
    <p>{`まずは教科書的な内容を CLINICS チームの`}<a parentName="p" {...{
        "href": "https://www.atlassian.com/ja/software/confluence"
      }}>{`Confluence`}</a>{`に書いて、講義形式で CLINICS 開発チームのメンバーに説明し、実践編として宿題を出して答え合わせと解説を行う、という流れで行っています。
学習者としては話を聞いているだけでは覚えにくく、実際に手を動かしたり、日々の業務で本当に困った経験をすると学びたい欲に火がつくと思っているので、実践を大切にしています。
演習問題は以下のような書籍を参考にして作問しています。ありがとうございます、著者の方々。`}</p>
    <p>{`『`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E3%83%86%E3%82%B9%E3%83%88%E6%8A%80%E6%B3%95%E3%83%89%E3%83%AA%E3%83%AB%E2%80%95%E3%83%86%E3%82%B9%E3%83%88%E8%A8%AD%E8%A8%88%E3%81%AE%E8%80%83%E3%81%88%E6%96%B9%E3%81%A8%E5%AE%9F%E9%9A%9B-%E7%A7%8B%E5%B1%B1-%E6%B5%A9%E4%B8%80/dp/4817193603/"
      }}>{`ソフトウェアテスト技法ドリル―テスト設計の考え方と実際`}</a>{` 』`}<br />{`
『`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/%E3%81%AF%E3%81%98%E3%82%81%E3%81%A6%E5%AD%A6%E3%81%B6%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E3%81%AE%E3%83%86%E3%82%B9%E3%83%88%E6%8A%80%E6%B3%95-%E3%83%AA%E3%83%BC-%E3%82%B3%E3%83%BC%E3%83%97%E3%83%A9%E3%83%B3%E3%83%89-ebook/dp/B00HE8082Q/"
      }}>{`はじめて学ぶソフトウェアのテスト技法`}</a>{` 』`}<br />{`
『`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E3%83%86%E3%82%B9%E3%83%88%E6%8A%80%E6%B3%95%E7%B7%B4%E7%BF%92%E5%B8%B3-%E7%9F%A5%E8%AD%98%E3%82%92%E7%B5%8C%E9%A8%93%E3%81%AB%E5%A4%89%E3%81%88%E3%82%8B40%E5%95%8F-%E6%A2%85%E6%B4%A5-%E6%AD%A3%E6%B4%8B/dp/429711061X/"
      }}>{`ソフトウェアテスト技法練習帳 ~知識を経験に変える 40 問~`}</a>{` 』`}</p>
    <p>{`一度教わっただけではなかなか覚えるのも難しいので、大事なテスト技法（境界値分析とか…）は折に触れて何度も何度も口にするようにしています。`}</p>
    <h1>{`（再）「CLINICS の QA」とは？`}</h1>
    <p>{`冒頭で引用した `}<a parentName="p" {...{
        "href": "/entry/2021/01/15/180126"
      }}>{`Magic Pod 導入の記事`}</a>{` では、「テストの自動化は（リリース後即座に修正できない）アプリから着手していく」方針としました。
現在は、CLINICS の Web ページ側のテスト自動化も推進しています。`}</p>
    <p>{`テスト自動化の目的は現場によっていろいろと思いがあるものです。なぜ「テスト自動化をやるのか？」については、機械にリグレッションテストを任せて手が空いた分、より高度な（経験則が必要な）探索的テストができるようになるから、と考えています。`}</p>
    <p>{`最初の問いに戻ります。`}<br />{`
「CLINICS の QA」とは何か？`}</p>
    <p>{`品質向上する仕組みが自然にできている自律した組織で、私は開発チームメンバーと「おもしろいテスト」「楽しいテスト」をしていきたい、と思っています。
それによって顧客が出会う可能性のある不具合が減り、「そもそも品質の高いプロダクトをつくることができるという世界」に近づけるのではないかな、と考えています。`}</p>
    <p>{`「おもしろいテスト」「楽しいテスト」とは発見であり、学習であり、フィードバックのサイクルによって生まれます。
そのためにも前述の「プロセス改善」と「知識の底上げ」は両輪で進めていく必要があります。`}</p>
    <p>{`品質向上のための手段は、テストの他にも実に多岐に渡ります。
長年やってきた私もまだ全貌を掴み切れていない「QA のエンジニアリング」ってこんなに奥深く楽しい！　ということが開発チームメンバーの共通認識になるとうれしいです。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210305/20210305203713.png",
      "alt": "20210305203713.png"
    }}></img>
    <p>{`最後までお読みいただきありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      